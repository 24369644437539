<template>
  <div></div>
</template>
<script setup>
import { t } from '../../languages';
import JSEncrypt from "jsencrypt";
import CryptoJS from "crypto-js";
import user from "@/network/user";
import { store } from "@/store";
import system from "@/network/system";
import { ElMessage } from "element-plus";
import { useRoute, useRouter } from "vue-router";
import { ref } from "vue";

const route = useRoute(); //获取参数
const router = useRouter(); //路由跳转

function login() {
  const { userName, password, sn } = route.query;
  if (
    userName != undefined &&
    userName != "" &&
    password != undefined &&
    password != "" &&
    sn != undefined &&
    sn != ""
  ) {
    automatic(userName, password, sn);
  } else if (
    userName != undefined &&
    userName != "" &&
    password != undefined &&
    password != "" &&
    sn === undefined
  ) {
    automatic(userName, password, sn);
  } else {
    ElMessage.warning("免登录缺少账号或密码");
    router.push({
      path: "/",
    });
    return false;
  }
}

//设置cookie
function setCookie(name, value) {
  document.cookie = name + "=" + escape(value); //转码并赋值
}

async function automatic(userName, password, sn) {
  let publicKey = "";
  const result = await user.getPublicKey();
  if (result.resultStatus) {
    publicKey = result.resultData;
  }
  const encryptor = new JSEncrypt();
  if (!publicKey) return;
  encryptor.setPublicKey(publicKey); // 设置公钥
  const encryption = encryptor.encrypt(password); // 对需要加密的数据进行加密
  if (!encryption) return;
  const postData = {
    user: userName,
    password: encryption,
  };
  const res = await user.login({
    loginData: postData,
    loginType: "USER_PASS",
  });
  if (res.resultStatus) {
    // 登录成功 -- 保存用户信息
    ElMessage.success("登录成功");
    // openSocketConnect();
    localStorage.setItem("token", res.resultData.token);
    setCookie("token", "hello"); //存储localStorage的同时，也存储一个cookie来监听
    localStorage.setItem(
      "permissions",
      res.resultData.userInfo.rolePermissions
    );
    // const aesEncrypt = CryptoJS.AES.encrypt(
    //     password,
    //     "123456"
    //   ).toString();
    //   localStorage.setItem(
    //     "rememberInfo",
    //     JSON.stringify({
    //       user: userName,
    //       password: aesEncrypt,
    //     })
    //   );
    // 保存用户基本信息
    localStorage.setItem("userInfo", JSON.stringify(res.resultData.userInfo));
    console.log(res.resultData.userInfo);
    sessionStorage.setItem("startTime", new Date().getTime().toString());
    // 同步设置vuex
    store.commit("setUserInfo", res.resultData.userInfo);
    system.getTenant().then((res) => {
      if (res.resultStatus) {
        // 设置租户
        localStorage.setItem("tenant", res.resultData);
      }
    });
    if (sn != undefined) {
      router.push(`/commandcenterIndex?sn=${sn}`);
      localStorage.setItem("menusId", 2);
    } else {
      router.push("/homeIndex");
      localStorage.setItem("menusId", 1);
    }
  }else{
      router.push("/");
  }
}

login();
</script>